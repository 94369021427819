import type { NextPage } from 'next'
import { useSession, signIn, signOut } from 'next-auth/react'
import BodyText from '../lib/components/body-text'
import PageHeader from '../lib/components/page-header'

const Login: NextPage = () => {
  return (
    <>
      <PageHeader title="Log in" />
      <div className="mt-4">
        <BodyText>Buy more credits for use in Figma by connecting your account below.</BodyText>
      </div>
      <div className="mt-4 flex justify-center">
        <button
          onClick={() => signIn('figma', { callbackUrl: '/account' })}
          className="flex w-[240px] items-center justify-center rounded-full bg-green-600 px-4 py-2 font-semibold text-white shadow hover:bg-green-500"
        >
          <img src="/figma.svg" className="mr-2 h-5" /> Sign in with Figma
        </button>
      </div>
    </>
  )
}

export default Login
